







import Vue from 'vue'
import PageContact from '../components/PageContact.vue'

export default Vue.extend({
  name: 'Contact',

  components: {
    PageContact
  }
})
