







import Vue from 'vue'
import PageProjets from '../components/PageProjets.vue'

export default Vue.extend({
  name: 'Projets',

  components: {
    PageProjets
  }
})
