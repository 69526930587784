







import Vue from 'vue'
import PagePart from '../components/PagePart.vue'

export default Vue.extend({
  name: 'part',
  components: {
    PagePart
  }
})
