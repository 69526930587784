



















import Vue from 'vue'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'titlep',
  data: () => ({
    sheet: false,
    show: false,
    mdt: 'auto',
    lgt: '4',
    filterTag: ''
  }),
  computed: {
    projects: function () {
      const _ps = JSON.stringify(i18n.t('Projects'))
      const _projects = JSON.parse(_ps) as Array<any>
      const _projectss = Array<any>()

      for (const project in _projects) {
        _projectss.push(_projects[project])
      }

      return _projectss.filter((p:any) => p.tag === this.filterTag || this.filterTag === '')
    }
  }
})
