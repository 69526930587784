





















import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'

export default Vue.extend({
  data: () => ({
    networks: [
      {
        icon: 'mdi-linkedin',
        url: 'https://www.linkedin.com/in/mathieu-mazal-51b130124/'
      },
      {
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/mathieumzl/'
      },
      {
        icon: 'mdi-artstation',
        url: 'https://www.artstation.com/mathieu_mazal'
      },
      {
        icon: require('@/assets/logo-soft/behance.svg'), // Charge le fichier SVG
        url: 'https://www.behance.net/mathieu_mzl',
        isSvg: true // Indique que c'est un SVG
      }
    ]
  }),
  methods: {
    onclick: function (link:string) {
      window.open(link, '_blank')
    }
  }
})
