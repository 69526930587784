







































import Vue from 'vue'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'PrestationList',
  data: () => ({
    filterTag: '',
    mdt: 'auto'
  }),
  computed: {
    PrestationsPro (): Array<any> {
      return i18n.t('PrestationsPro') as unknown as Array<any>
    }
  },
  methods: {
    scrollTo (targetId: string) {
      const element = document.querySelector(targetId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  mounted () {
    if (window.location.hash) {
      this.scrollTo(window.location.hash)
    }
  }
})
