















import { getSupportedLocales } from '@/utils/i18n/supported-locales'
import i18n from '@/i18n'
import Vue from 'vue'

export default Vue.extend({
  created: function () {
    i18n.locale = this.$store.state.locale
  },
  methods: {
    switchLanguage: function (code:string): void {
      this.$store.dispatch('switchlanguages', code)
    }
  },
  computed: {
    locales: function () {
      return getSupportedLocales().filter(i => i.code !== this.$store.state.locale)
    }
  }
})
