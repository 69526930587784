import { render, staticRenderFns } from "./CardsProjectsPresentation.component.vue?vue&type=template&id=0aec7ddf&scoped=true&"
import script from "./CardsProjectsPresentation.component.vue?vue&type=script&lang=ts&"
export * from "./CardsProjectsPresentation.component.vue?vue&type=script&lang=ts&"
import style0 from "./CardsProjectsPresentation.component.vue?vue&type=style&index=0&id=0aec7ddf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aec7ddf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardTitle,VChip,VChipGroup,VCol,VContainer,VRow})
